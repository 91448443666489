<template>
  <form
    id="vidjet-form"
    class="vidjet-input-form"
    :class="[{ inputSent: inputSent }, buttonShape]"
    @submit.prevent="inputSubmit"
    :style="cssVars"
  >
    <input
      :type="inputType"
      class="vidjet-input-input"
      id="vidjet-input"
      name="vidjet-input"
      :placeholder="$t('player.customInput.emailPlaceholder')"
      autocomplete="email"
      required
      v-model="input"
      :style="cssVars"
    />
    <button
      class="vidjet-input-button"
      :class="{ 'button-round': cta.buttonShape === 'round' }"
      :style="cssVars"
    >
      <CheckIcon v-if="inputSent" :style="cssVars" />
      <PlaneButton v-else />
    </button>
  </form>
</template>

<script>
import PlaneButton from "@/assets/plane-button.svg";
import CheckIcon from "@/assets/check-icon.svg";
import enums from "@/enums.js";
import { mapGetters, mapActions } from "vuex";

const { CtaType } = enums;

export default {
  components: {
    PlaneButton,
    CheckIcon,
  },

  data() {
    return {
      input: "",
      inputSent: false,
    };
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters("videos", ["getCtaByIndex"]),
    ...mapGetters("playerSite", ["site"]),
    ...mapGetters("player", ["isInPreview"]),
    ...mapGetters("playerCampaign", ["format"]),

    globalCouponCode() {
      return this.site.globalCouponCode;
    },

    cta() {
      return this.getCtaByIndex(this.videoIndex);
    },

    inputType() {
      return this.cta.ctaType === CtaType.input ? "email" : "input";
    },

    cssVars() {
      return {
        "--cta-color": this.format?.cta?.buttonColor,
      };
    },
    buttonShape() {
      if (this.format.cta.buttonShape) {
        return `border-${this.format.cta.buttonShape}`;
      } else {
        return "border-semi";
      }
    },
  },

  methods: {
    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),
    inputSubmit() {
      if (!this.isInPreview) {
        this.sendEvent({
          type: "emailCollected",
          value: this.input,
        });
      }
      this.inputSent = true;
      this.input = this.globalCouponCode
        ? `Code: ${this.globalCouponCode}`
        : this.$t("player.customInput.sentSuccessfully");
    },
  },
};
</script>

<style lang="scss" scoped>
.vidjet-input-form {
  opacity: 1;
  display: grid;
  grid-template-columns: 1fr 25px;
  transition: opacity 1s ease-in-out;
  border-radius: 20px;
  background: #ebebeb;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  transition: transform 0.2s ease-in-out;
  border: 2px solid var(--cta-color);
  width: 100%;
}

.vidjet-input-form.inputSent {
  .vidjet-input-button {
    svg {
      :nth-child(1) {
        fill: var(--cta-color);
      }
    }
  }
  .vidjet-input-input {
    color: var(--cta-color);
    transition: all 0.3s ease-in;
  }
}

.vidjet-input-input {
  flex: 1;
  font-size: 14px;
  color: black;
  line-height: 17px;
  font-family: "Gothic A1", sans-serif;
  font-weight: 500;
  padding: 4px 4px 4px 8px;
  width: 100%;
  max-width: calc(100% - 22px);
  outline: none;
  margin: 0;
  max-width: 100%;
  // input css reset
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.border-square {
  border-radius: 1px;
}
.border-semi {
  border-radius: 8px;
}
.border-round {
  border-radius: 100px;
}

.vidjet-input-input:focus {
  outline: none;
}

.vidjet-input-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  min-width: 20px;
  background: var(--cta-color);
}

.button-round {
  border-radius: 0 20px 20px 0;
}

@media screen and (max-width: 320px) {
  .vidjet-input-input {
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
